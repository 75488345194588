import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import { DefaultSectionPageSkeleton, DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const DefaultSectionPage = loadable(() => import('../pages/DefaultSectionPage'), {
  fallback: <DefaultSectionPageSkeleton />
})
const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const Business = (props) => (
  <Switch>
      <Route path='/business' exact render={() => (
        <DefaultSectionPage
          {...props} isPage0={props.isPage0}
          section='business'
          adPath='/business/homepage'
          mostReadSection='business-report'
          relatedSections={[{ id: 'business-report' }, { id: 'technology' }, { id: 'personal-finance' }, { id: 'business/advice' }, { id: 'business/jobs' }, { id: 'business/savings' }, { id: 'property' }]}
          title='Business | IOL News'
          description=''
        />
      )}
    />
    <Route path='/business/advice' exact render={() => (
      <TitleSectionPage {...props}
        section='business/advice'
        relatedSections={[{ id: 'business-report' }, { id: 'technology' }, { id: 'personal-finance' }, { id: 'business/jobs' }, { id: 'business/savings' }, { id: 'property' }]}
        title='Business Advice | IOL'
        description=''
      />
    )} />
    <Route path='/business/jobs' exact render={() => (
      <TitleSectionPage {...props}
        section='business/jobs'
        relatedSections={[{ id: 'business-report' }, { id: 'technology' }, { id: 'personal-finance' }, { id: 'business/advice' }, { id: 'business/savings' }, { id: 'property' }]}
        title='Jobs | IOL'
        description=''
      />
    )} />
    <Route path='/business/savings' exact render={() => (
      <TitleSectionPage {...props}
        section='business/savings'
        relatedSections={[{ id: 'business-report' }, { id: 'technology' }, { id: 'personal-finance' }, { id: 'business/advice' }, { id: 'business/jobs' }, { id: 'property' }]}
        title='Business Savings | IOL'
        description=''
      />
    )} />
    <Route path='/business/hybrid-working' exact render={() => (
      <DefaultSectionPage {...props}
        section='business/hybrid-working'
        relatedSections={[{ id: 'business-report' }, { id: 'technology' }, { id: 'personal-finance' }, { id: 'business/advice' }, { id: 'business/jobs' }, { id: 'property' }]}
        title='Business Hybrid Work | IOL'
        description=''
      />
    )} />
    <Route path={'/business/advice/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/business/jobs/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/business/savings/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/business/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/business/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/business/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/business/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/business/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/business/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default Business
